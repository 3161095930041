import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import Axios from 'axios';
import { Alert, Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from 'notistack';
import { styled, lighten, darken } from '@mui/system';

import GetProductType from "./Functions/GetProductType.js";
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import { AddOtherDocument } from '../../../Actions/Menu';
import { SetTripDocs, SetTripDocsDoc } from "../../../Actions/Menu";
import localeText from '../../Functions/localeText';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hr: {
        border: 0,
        height: 0,
        borderTop: '1px solid rgba(0, 0, 0, .1)',
        borderBottom: '1px solid rgba(255, 255, 255, .3)',
        width: '100%'
    },
    ftContainer: {
        // marginTop: "5px !important",
        marginBottom: "32px !important"
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    }
}));

type Props = {
    trip_displayed_version: number,
    modalUploadVoucher: boolean,
    allVoucher: any[],
    setModalUploadVoucher: (value: boolean) => void,
}

type Document = {
    bucket_name: string | null,
    bucket_region: string | null,
    created_date: Date,
    creator: number,
    display_minisite: boolean,
    display_voucher: boolean,
    file_name: string | null,
    id: number,
    last_author: number,
    modified_date: Date,
    object_name: string | null,
    owner: number, 
    product: number | null,
    received: boolean, 
    requested: boolean, 
    trip: number, 
    trip_version: number,
    type: string | null,    
}

export function TripListUploadVoucher(props: Props): JSX.Element {
    const { trip_displayed_version, modalUploadVoucher, setModalUploadVoucher, allVoucher } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const uploadVoucherInputRef = useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const trip_info = useSelector(store => store.menu.trip_info);
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const pois = useSelector(store => store.poi.cart);
    const flights = useSelector(store => store.flight.cart);
    const cars = useSelector(store => store.cars_search.cart);
    const transfers = useSelector(store => store.transfers.cart);
    const accommodations = useSelector(store => store.accommodation.sort_cart);
    const manual_products = useSelector(store => store.cart.manual_item_list);
    const assistances = useSelector(state => state.cart.assistance_cart);
    const locales = useSelector(state => state.user.locales);

    const [file, setFile] = useState<File | null>(null);
    const [voucherList, setVoucherList] = useState<any[]>([]);
    const [voucherToReplace, setVoucherToReplace] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const bucketName = "facilitatrip-documents";
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const handleModalUploadVoucher = (event: React.MouseEvent<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        if (reason !== 'backdropClick') {
            setModalUploadVoucher(!modalUploadVoucher);
            setVoucherToReplace(null);
            setFile(null);
        }
    };
    const handleReplaceVoucher = () => {
        if (voucherToReplace !== null && voucherToReplace !== undefined) {
            setLoading(true);
            console.log('file:', file);
            let name_split = file.name !== null ? file.name.split(".") : [];
            let extention = name_split.length !== 0 ? name_split[name_split.length - 1] : "";
            let formData = new FormData();
            let find_voucher = allVoucher.find((voucher: any) => voucher.product_ids.includes(voucherToReplace.id));
            const domain = window.location.host.replace("www.", "").split('.');
            formData.append("file_object", file);
            formData.append("bucket_name", bucketName);
            formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
            formData.append("type", "CUSTOM_VOUCHER");
            formData.append("product", voucherToReplace.id);
            formData.append("file_name", find_voucher !== undefined ? `${encodeURIComponent(find_voucher.name.slice(0, 250).replaceAll("/", "-"))}.${extention}` : `${getOptionLabel(voucherToReplace)}.${extention}`);


            // if (doc !== undefined) {
            //     formData.append("tripdocument_id", doc.id);
            // }
            let { pass_check, headers } = CheckBeforeRequest();
            headers["Content-Type"] = "multipart/form-data";
            if (pass_check) {
                Axios({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`,
                    headers: headers,
                    data: formData
                }).then(function (response) {
                    enqueueSnackbar(t<string>("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                    dispatch(AddOtherDocument(response.data));
                    if (allVoucher.length > 0) {
                        let found_doc = trip_docs.find((doc: Document) => doc.product === voucherToReplace.id);
                        if (found_doc !== undefined) {
                            let request = {
                                display_minisite: false,
                                display_voucher: false
                            };
                            headers["Content-Type"] = "application/json";
                            Axios({
                                method: "PATCH",
                                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${found_doc.id}/`,
                                headers: headers,
                                data: JSON.stringify(request)
                            }).then(function (response1) {
                                setModalUploadVoucher(false);
                                setFile(null);
                                setVoucherToReplace(null);
                                setLoading(false);
                                dispatch(SetTripDocsDoc(response1.data));
                            }).catch((error) => {
                                console.log('error patch request:', error);
                            });
                        } else {
                            setModalUploadVoucher(false);
                            setFile(null);
                            setVoucherToReplace(null);
                            setLoading(false);
                        }
                    } else {
                        setModalUploadVoucher(false);
                        setFile(null);
                        setVoucherToReplace(null);
                        setLoading(false);
                    }
                }).catch(function(error) {
                    console.log(error.responseJSON);
                    setModalUploadVoucher(false);
                    setLoading(false);
                    enqueueSnackbar(t<string>("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                })
            }
        }
    }
    const checkFile = (file: ChangeEvent<HTMLInputElement>) => {
        const trim_file = file.target.value.replaceAll(" ", "").toLowerCase();
        const allowedFiles = ["png", "jpg", "jpeg", "pdf", "csv", "xml", "xls","xlsx", "doc", "docx", "heic", "heif"];
        // const allowedExtensions = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
        let name_split = trim_file.split(".").pop();
        // if (!allowedExtensions.exec(trim_file)) {
        //     return false;
        // }
        if (!allowedFiles.includes(name_split)) {
            return false;
        }
        return true;
    }
    const checkSize = (file: ChangeEvent<HTMLInputElement>) => {
        if ((file.target.files[0].size/1024)/1024 > 10) {
            return false;
        }
        return true;
    }
    const uploadVoucher = (e: ChangeEvent<HTMLInputElement>) => {
        let selected_file = e.target.files[0];
        let check_format = checkFile(e);
        let check_size = checkSize(e);
        if (check_format && check_size) {
            setFile(selected_file);
            e.target.value = null;
        } else {
            e.target.value = null;
            if (!check_format)
                enqueueSnackbar(t<string>("menu.trip_list.failed_doc_upload_type"), { variant: "error", disableWindowBlurListener: true });
            if (!check_size)
                enqueueSnackbar(t<string>("menu.trip_list.failed_doc_upload_size"), { variant: "error", disableWindowBlurListener: true });
        }
    }
    const getOptionLabel = (option: any) => {    
        let name = '';  
        switch (option.product_type) {
            case 0:
                name = `${option.hotel !== undefined ? option.hotel[0].name : option.name} : ${option.rooms.length > 1 ? (`${option.rooms.length} ${t<string>('shared.rooms')}`) : (`${option.rooms.length} ${t<string>('shared.room')}`)}`;
                break;
            case 6:
                if (option.outbounds !== undefined && option.outbounds !== null) {
                    name = option.outbounds[0].legs[0].marketing_airline.commercial_name + " : " + (option.outbounds[0].legs[0].origin !== null && option.outbounds[0].legs[0].destination !== null ? option.outbounds[0].legs[0].origin.airport_code + " - " + option.outbounds[0].legs[0].destination.airport_code : option.outbounds[0].legs[0].origin_station.station_code + " - " + option.outbounds[0].legs[0].destination_station.station_code);
                    break;
                }
            case 11:
            case 12:
                // name = localeText(current_locale, option.localization,!option.is_custom && option.custom_product ? localeText(current_locale, option.custom_product.localization ? option.custom_product.localization : option.localization, option.custom_product.title, 'title') : option.name, 'name');
                // break;
                name = localeText(current_locale.id, option.localization , option.custom_product ? localeText(current_locale.id, option.custom_product.localization ? option.custom_product.localization : option.localization, option.custom_product.title, 'title') : option.name, 'name');
                break;
                // if (option.custom_product !== undefined && option.custom_product !== null) {
                //     name = localeText(current_locale.id, option.custom_product.localization ? option.custom_product.localization : option.localization, option.custom_product.title, 'title');
                //     break;
                // } else {
                //     console.log("current_locale : ", current_locale);
                //     console.log("option : ", option);
                //     name = localeText(current_locale.id, option.localization, option.name, 'name');
                //     break;
                // }

                // if (option.custom_product !== undefined && option.custom_product !== null) {
                //     name = option.custom_product.title;
                //     break;
                // } else {
                //     name = option.name;
                //     break;
                // }
            case 8:
                name = !!option.contract_name ? option.contract_name : t('cart-material.assistance');
                break;
            default:
                if (option.name !== undefined && option.name !== null) {
                    name = option.name;
                }
        }
        return name;
    };
    useEffect(() => {
        if (modalUploadVoucher) {
            console.log('trip_docs:', trip_docs);
            let tmp_manual_products = [...manual_products].filter((manual_product: any) => {
                return  manual_product.auto_product === null && ![16, 20, 21].includes(manual_product.product_type) && (manual_product.is_available || (manual_product.booking_status !== null && manual_product.booking_status.status_booking === 'CONFIRMED')) && manual_product.is_displayed && !manual_product.poi_type && !manual_product.is_optional && !manual_product.is_hidden_for_traveler && (manual_product.booking_status === null || (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === manual_product.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((manual_product: any) => {
                let product_type = GetProductType(manual_product.product_type);
                return {
                    ...manual_product,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_assistance = [...assistances].filter((assistance: any) => {
                return (assistance.is_available || (assistance.booking_status !== null && assistance.booking_status.status_booking === 'CONFIRMED')) && assistance.is_displayed && !assistance.is_optional && !assistance.is_hidden_for_traveler && (assistance.booking_status === null || (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === assistance.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((assistance: any) => {
                let product_type = GetProductType(assistance.product_type);
                return {
                    ...assistance,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_flights = [...flights].filter((flight: any) => {
                return (flight.is_available || (flight.booking_status !== null && flight.booking_status.status_booking === 'CONFIRMED')) && flight.is_displayed && !flight.is_optional && !flight.is_hidden_for_traveler && (flight.booking_status === null || (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === flight.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((flight: any) => {
                let product_type = GetProductType(flight.product_type);
                return {
                    ...flight,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_cars = [...cars].filter((car: any) => {
                return (car.is_available || (car.booking_status !== null && car.booking_status.status_booking === 'CONFIRMED')) && car.is_displayed && !car.is_optional && !car.is_hidden_for_traveler && (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === car.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((car: any) => {
                let product_type = GetProductType(car.product_type);
                return {
                    ...car,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_transfers = [...transfers].filter((transfer: any) => {
                return (transfer.is_available || (transfer.booking_status !== null && transfer.booking_status.status_booking === 'CONFIRMED')) && transfer.is_displayed && !transfer.is_optional && !transfer.is_hidden_for_traveler && (transfer.booking_status === null || (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === transfer.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((transfer: any) => {
                let product_type = GetProductType(transfer.product_type);
                return {
                    ...transfer,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_pois = [...pois].filter((poi: any) => {
                return (poi.is_available || (poi.booking_status !== null && poi.booking_status.status_booking === 'CONFIRMED')) && !poi.poi_type && poi.is_displayed && !poi.is_optional && !poi.is_hidden_for_traveler && (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === poi.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((poi: any) => {
                let product_type = GetProductType(poi.product_type);
                return {
                    ...poi,
                    group: t<string>('providers.' + product_type)
                }
            });
            let tmp_accommodation = [...accommodations].filter((accommodation: any) => {
                return (accommodation.is_available || (accommodation.booking_status !== null && accommodation.booking_status.status_booking === 'CONFIRMED')) && accommodation.is_displayed && !accommodation.is_optional && !accommodation.is_hidden_for_traveler && (accommodation.booking_status === null || (accommodation.booking_process_state !== 'CANCEL' && accommodation.booking_status.status_booking !== 'CANCELLED')) && trip_docs.find(doc => doc.product === accommodation.id && doc.type === 'CUSTOM_VOUCHER') === undefined
            }).map((accommodation: any) => {
                let product_type = GetProductType(accommodation.product_type);
                return {
                    ...accommodation,
                    group: t<string>('providers.' + product_type)
                }
            });

            let all_voucher_doc = [...tmp_accommodation, ...tmp_cars, ...tmp_flights, ...tmp_transfers, ...tmp_pois, ...tmp_assistance, ...tmp_manual_products];
            setVoucherList(all_voucher_doc);
        }
    }, [modalUploadVoucher]);
    return (
        <Dialog open={ modalUploadVoucher } fullWidth  onClose={ handleModalUploadVoucher } maxWidth={ "md" } disableEscapeKeyDown>
            <DialogTitle>
                <Grid container justifyContent={ 'space-between' } alignItems={ 'center' }>
                    <Grid item>{ t<string>('menu.trip_list.add_voucher') }</Grid>
                    <Grid item>
                        <IconButton size={"small"} onClick={ handleModalUploadVoucher }><Close/></IconButton>
                    </Grid>
                </Grid>
                <hr className={ classes.hr }/>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={'column'} spacing={ 4 }>
                    <Grid item>
                        <input hidden ref={uploadVoucherInputRef} type="file" onChange={uploadVoucher}/>
                        <Button startIcon={file ? undefined : <Add />} color={ "primary" } size={ "small" } component={ "label" } onClick={() => uploadVoucherInputRef.current && uploadVoucherInputRef.current.click()}>
                        {file ? `${file.name} ${Math.round(file.size / 1000)}kB` : (t<string>("menu.trip_list.add_voucher")) }
                        </Button>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={voucherList.sort((a, b) => a.product_type < b.product_type ? -1 : 1)}
                            getOptionLabel={(option) => getOptionLabel(option)}
                            groupBy={(option) => option.group}
                            onChange={(event: any, newValue: any) => {
                                setVoucherToReplace(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label={t<string>('menu.trip_list.replace_voucher_text')} />}
                            renderGroup={(params) => (
                                <li key={params.key}>
                                    <GroupHeader>{params.group}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )}
                        />
                    </Grid>
                    {
                        voucherToReplace !== null &&
                        <Grid item>
                            <Alert severity="info">{t<string>('menu.trip_list.replace_voucher_alert') + ' ' + getOptionLabel(voucherToReplace)}</Alert>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            {
                voucherToReplace !== null && file !== null &&
                <DialogActions>
                    <Button onClick={handleModalUploadVoucher} color="primary">
                        {t<string>('shared.cancel')}
                    </Button>
                    {
                        <Button onClick={handleReplaceVoucher} color="primary">
                        {
                            loading ?
                            <CircularProgress size={ 20 } />
                            :
                            t<string>('menu.trip_list.replace_voucher')
                        }
                        </Button>
                    }
                </DialogActions>
            }
        </Dialog>
    );
}
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });